<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>מרכז פניות</template>
      <template #button>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import RequestEntity from "@/views/RequestEntity.vue";
import DialogChat from '@/components/DialogChat.vue';
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("requests");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: RequestEntity,
        preview: true,
        readOnly: false,
        title: "פנייה",
        recordUrl: "admin/reports",
        recordTitle: "הצג צ'אט",
        hideRecord: true,
        modal: DialogChat
      };
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.Fullname, checked: true },
        { ...reportsFields.SourceType, checked: true },
        { ...reportsFields.Subject, checked: true },
        { ...reportsFields.Description, checked: true },
        { ...reportsFields.SubSubject, checked: true },
        { ...reportsFields.CreateDate, checked: true },
        { ...reportsFields.Status, checked: true },
        { ...reportsFields.Chat, checked: true },
      ];

      this.tabs = [
        {
          id: "AllRequests",
          title: "כל הפניות",
          getDataAsync: this.getAllRequests,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.TaggedAdmin },
            { ...reportsfilters.Terms },
            { ...reportsfilters.SentFromPage },
            { ...reportsfilters.MessageTypeId },
            { ...reportsfilters.SearchFilterType },
            { ...reportsfilters.Status },
            { ...reportsfilters.AllowedPublication },
            { ...reportsfilters.QuestionId },
            { ...reportsfilters.RespondingAdminId },
            { ...reportsfilters.QuestionCategoryIds },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש פנייה",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
        {
          id: "RequestsTaggedByMe",
          title: "פניות שלי",
          getDataAsync: this.getAllRequests,
          perPage: 100,
          fields,
          filters: [
            { ...reportsfilters.TaggedAdmin, selected: this.$store.state.auth.user.email },
            { ...reportsfilters.Terms },
            { ...reportsfilters.SentFromPage },
            { ...reportsfilters.MessageTypeId },
            { ...reportsfilters.SearchFilterType },
            { ...reportsfilters.Status },
            { ...reportsfilters.AllowedPublication },
            { ...reportsfilters.QuestionId },
            { ...reportsfilters.RespondingAdminId },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש פנייה",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllRequests({ cancelToken, ...rest }) {
      return CoursesService.getRequestsByCourse(
        this.$store.state.auth.course?.id,
        {
          ...rest,
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
